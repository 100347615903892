import WebServiceRequest from '../Api/WebServiceRequest'

class StorySetStoryRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Story/SetStory')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class StoryGetStoryRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Story/GetStories')
    }
}
class StoryGetStoryReportsRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Story/GetStoryReports')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class StoryGetAllStoriesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Story/GetAllStories')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export  {
    StorySetStoryRequest,
    StoryGetStoryRequest,
    StoryGetStoryReportsRequest,
    StoryGetAllStoriesRequest
};
