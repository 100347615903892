import WebServiceRequest from '../Api/WebServiceRequest'

class StaticPageCreateRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('StaticPage/Create')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class StaticPageUpdateRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('StaticPage/Update')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class StaticPageGetByTypeRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('StaticPage/GetByType')
    }

    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    StaticPageCreateRequest,
    StaticPageUpdateRequest,
    StaticPageGetByTypeRequest
};
