<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row v-if="images">
      <b-col cols="12">
        <b-card class="">
          <h4>بنر بخش معرفی</h4>

          <b-row>
            <b-media class="my-2 col-12">
              <template>
                <b-avatar
                    class="cursor-pointer w-25"
                    ref="previewEl"
                    :src="base64ImageSrc1"
                    size="300px"
                    rounded
                    @click.native="$refs.refInputEl1.click()"
                >
                </b-avatar>
              </template>
            </b-media>

          </b-row>

          <b-button
              variant="warning"
              @click="$refs.refInputEl1.click()"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
          >
            <input
                ref="refInputEl1"
                type="file"
                class="d-none"
                @input="makeBase64Pic($event,'base64ImageSrc1')"
            >
            <span class="d-none d-sm-inline">ویرایش</span>
            <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
            />
          </b-button>

          <b-button
              variant="primary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="setPic(1)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="images">
      <b-col cols="12">
        <b-card class="">
          <h4>بنر مشاوره خصوصی</h4>

          <b-row>
            <b-media class="my-2 col-12">
              <template>
                <b-avatar
                    class="cursor-pointer w-25"
                    ref="previewEl"
                    :src="base64ImageSrc2"
                    size="300px"
                    rounded
                    @click.native="$refs.refInputEl2.click()"
                >
                </b-avatar>
              </template>
            </b-media>

          </b-row>

          <b-button
              variant="warning"
              @click="$refs.refInputEl2.click()"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
          >
            <input
                ref="refInputEl2"
                type="file"
                class="d-none"
                @input="makeBase64Pic($event,'base64ImageSrc2')"
            >
            <span class="d-none d-sm-inline">ویرایش</span>
            <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
            />
          </b-button>

          <b-button
              variant="primary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="setPic(2)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card v-for="item in pageData">

          <b-form-group
              :label="item.title"
              label-for="blog-content"
          >
            <b-form-textarea
                id="blog-content"
                rows="4"
                v-model="item.content"
                class="my-2"
            ></b-form-textarea>
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateSection(item)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import vSelect from "vue-select";
import {StorySetStoryRequest} from "@/libs/Api/Story";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {AccountGetSliderRequest, AccountSetSliderRequest} from "@/libs/Api/Account";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";

export default {
  title: "ویرایش صفحه اصلی - پنل ادمین مکس ",
  name: "Home",
  data() {
    return {
      pageData:null,
      overlay: false,
      images: null,
      base64ImageSrc1: null,
      base64ImageSrc2: null,
      pic1: null,
      pic2: null,
    }
  },
  async created() {
    await this.getPics();
    await this.getData();
  },
  methods: {
    async getData(){
      let _this = this;
      _this.overlay = true;

      let data = {type : 2};

      let staticPageGetByTypeRequest = new StaticPageGetByTypeRequest(_this);
      staticPageGetByTypeRequest.setParams(data);
      await staticPageGetByTypeRequest.fetch(function (content){
        _this.overlay = false;
        _this.pageData = content;
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async getPics() {
      let _this = this;
      _this.overlay = true;

      let accountGetSliderRequest = new AccountGetSliderRequest(_this)
      await accountGetSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.images = content;
        content.forEach(item => {
          if (item.priority === 1) {
            _this.base64ImageSrc1 = 'https://api.maxterms.com/media/gallery/slider/'+item.pictureUrl
          } else if (item.priority === 2) {
            _this.base64ImageSrc2 = 'https://api.maxterms.com/media/gallery/slider/'+item.pictureUrl
          }
        })
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      if (index === "base64ImageSrc1"){
        _this.pic1 = result;
      }else {
        _this.pic2 = result;
      }
    },
    async setPic(param) {
      let _this = this;
      _this.overlay = true;
      let data = null
      if (param === 1) {
        data = {
          base64: _this.pic1,
          priority: 1
        }
      } else {
        data = {
          base64: _this.pic2,
          priority: 2
        }
      }

      let accountSetSliderRequest = new AccountSetSliderRequest(_this);
      accountSetSliderRequest.setParams(data);
      await accountSetSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async updateSection(param){
      let _this = this;

      _this.showLoading=true;

      let staticPageUpdateRequest = new StaticPageUpdateRequest(_this);
      staticPageUpdateRequest.setParams(param);
      await staticPageUpdateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getData();
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
    deletePic() {
      this.storyData.base64 = null;
      this.base64ImageSrc = null;
    },
  },
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
}
</script>

<style scoped>

</style>